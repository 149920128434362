<template>
  <div class="pageContainer">
    <el-card shadow="never">
      <div slot="header">
        <el-form :inline="true">
          <el-form-item label="园区">
            <el-select v-model="query.parkId"
                       clearable
                       filterable>
              <el-option v-for="item in parkList"
                         :label="item.name"
                         :key="item.id"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="queryData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
<!--      <el-row type="flex" justify="start">-->
<!--        <el-button type="primary" @click="handleClickAdd">新增园区资讯</el-button>-->
<!--      </el-row>-->
      <div class="tableWrap">
        <el-table :data="tableData"
                  border
                  v-loading="loadingList"
                  row-key="id">
          <el-table-column fixed prop="title" label="资讯标题" min-width="100">
            <template v-slot="{ row }">
              <router-link :to="{name: 'parkNewsDetail', params: { parkNewsId: row.id }}"><el-button type="text">{{row.title}}</el-button></router-link>
            </template>
          </el-table-column>
          <el-table-column prop="publisher" label="发布者" min-width="80"></el-table-column>
          <el-table-column label="园区资讯封面" width="140">
            <template v-slot="{ row }">
              <img :src="row.cover" class="displayImg" alt="">
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              label="操作"
              width="120">
            <template v-slot="{ row }">
              <el-button type="text" @click="handleDelete(row)">删除</el-button>
              <el-button type="text" @click="handleEdit(row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          :current-page.sync="pageObj.currentPage"
          :total="pageObj.total"
          :page-sizes="pageObj.pageSizes"
          :page-size="pageObj.pageSize"
          @current-change = "handlerPageChange"
          @size-change = "handlerSizeChange"
          :layout="pageObj.layout"></el-pagination>
    </el-card>
  </div>
</template>

<script>
import globalConfig from 'config/global'
import { execute } from './option'
import { mapActions } from 'vuex'
export default {
  name: 'caseList',
  data() {
    return {
      parkList: [],
      query: {
        parkId: undefined
      },
      tableData: [],
      loadingList: false,
      pageObj: {
        ...globalConfig.pagination,
        currentPage: 1,
        total: 0
      }
    }
  },
  created () {
    this.getParkListByPage()
    this.getListByPage(this.pageObj.currentPage)
  },
  methods: {
    ...mapActions(['getParkNewsList', 'deleteParkNews', 'getParkList']),
    // 新增
    handleClickAdd() {
      execute('addOrEditParkNews', {
        optType: 'add',
        callback: this.getListByPage
      })
    },
    queryData() {
      this.getListByPage(1)
    },
    getListByPage() {
      this.loadingList = true
      this.getParkNewsList({
        page: this.pageObj.currentPage,
        size: this.pageObj.pageSize,
        ...this.query
      }).then(res => {
        console.log(res)
        const { recordList, total } = res
        this.tableData = recordList || []
        this.pageObj.total = total
      }).finally(() => {
        this.loadingList = false
      })
    },
    handlerPageChange(page) {
      this.pageObj.currentPage = page
      this.getListByPage()
    },
    handlerSizeChange(size) {
      this.pageObj.pageSize = size
      this.getListByPage()
    },
    // 删除
    handleDelete(row) {
      this.$confirm('确认删除该园区资讯吗？').then(() => {
        this.deleteParkNews(row.id).then(() => {
          this.$message.success('操作成功')
        }).finally(() => {
          this.getListByPage()
        })
      })
    },
    handleEdit(row) {
      execute('addOrEditParkNews', {
        optType: 'edit',
        detail: { ...row },
        callback: this.getListByPage
      })
    },
    // 获取园区列表
    getParkListByPage() {
      this.loadingList = true
      this.getParkList({
        page: 1,
        size: 100,
        ...this.query
      }).then(res => {
        console.log(res)
        const { recordList } = res
        this.parkList = recordList || []
      }).finally(() => {
        this.loadingList = false
      })
    }
  }
}
</script>

<style scoped>
.pageContainer {
  padding: 20px;
}
.tableWrap {
  margin: 20px 0;
}
.displayImg {
  display: block;
  max-width: 120px;
}
</style>
